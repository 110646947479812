import React from "react";
import HTMLToReact from '../../components/HTMLToReact';
import { getPathInfo, getLastPage } from '../../services/URL';
import { processUserFacingString, getUserFacingLanguage } from '../../../processing/text';
import Container from '../../components/Container';
import { WEB } from '../../../config/platforms';
import Button from '../../components/Button';
import Helmet from 'react-helmet';
import Tabs from '../../components/Tabs';
import Languages from '../../../config/languages';
import './SampleCodeTemplate.scss';
import { navigate } from 'gatsby-link';
import { getPlatformLanguages } from "../../services/LanguageService";
import UserInput from "../../components/UserInput";
import { setItem, CACHE_KEYS, formatTitle } from "../../services/DocumentationService";
import Link from '../../components/Link'

export default class SampleCodeTemplate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      reason: '',
      selectedTabIndex: 0
    }
  }

  getCustomMetaData = (tag, sampleData, language, platform) => {
    let t = null;
    if (sampleData && sampleData.meta && sampleData.meta[tag] && typeof sampleData.meta[tag] === 'string') {
      t = sampleData.meta[tag];
      t = processUserFacingString(t, { language, platform }); 
    }
    return t;
  }

  getLanguageItems = (sampleData, platform) => {
    const { languages } = sampleData;
    return getPlatformLanguages(platform, languages, true);
  }

  getBackLink = (platform) => {
    if (platform === WEB) {
      const lastPage = getLastPage();
      if (lastPage && lastPage.indexOf('/web/samples') > -1) {
        return lastPage;
      } else if (lastPage && lastPage.indexOf('/samples') > -1) {
        return `/samples/?platforms=web`
      } else {
        return '/web/samples/viewing/';
      }
    }
    return `/samples/?platforms=${platform}`;
  }

  render() {
    const { location, pageContext } = this.props;

    let { files, language, fileName, sampleData } = pageContext;

    const body = files[this.state.selectedTabIndex].body
    const ogLanguage = language;

    if (language.indexOf('.') > -1) {
      language = language.split('.')[1];
    }

    const className = `language-${language}`;
    const html = `<div><pre class='${className}'><code class='${className}'>${body.replace(/</g, '&lt;').replace(/>/g, "&gt;")}</code></pre></div>`;

    let description = '';
    let title = `${fileName} - ${getUserFacingLanguage(language)}`;

    const { platform } = getPathInfo(location.pathname, location);

    if (sampleData && sampleData.meta) {
      description = sampleData.meta.description;
      title = sampleData.meta.title;
    }

    let h1 = this.getCustomMetaData('h1', sampleData, language, platform);
    let h2 = this.getCustomMetaData('h2', sampleData, language, platform);
    let meta = this.getCustomMetaData('meta', sampleData, language, platform);
    let browserTitle = formatTitle(this.getCustomMetaData('browserTitle', sampleData, language, platform));

    let desc = h2 || description;
    const { languages } = sampleData; // = this.getLanguageItems(sampleData, platform);
 
    return (
      <div className='SampleCodeTemplate'>
        
        <Helmet>
          {
            browserTitle &&
            <title>{browserTitle}</title>
          }

          {
            meta &&
            <meta name='description' content={meta} />
          }
        </Helmet>

        <Container> 

          <div className='title-row'>
            <h1>{`${h1 || title}`}</h1>

            {
              languages.length > 1 &&
              <UserInput
                type='select'
                placeholder='More languages'
                onChange={(to) => {
                  const path = location.pathname;
                  const goingTo = path.replace(`/${ogLanguage.replace('.', '/')}/`, `/${to.replace('.', '/')}/`);
                  const { platform } = getPathInfo(goingTo);
                  if (platform) { setItem(platform, CACHE_KEYS.LANGAUAGE, to) }
                  navigate(
                    goingTo
                  );
                }}
                options={languages.map(item => ({ name: item, label: Languages.FORMATTED_LANGUAGES[item] || item }))}
              />
            }
          </div>
          
          {
            (desc) &&
            <p className='subtitle' dangerouslySetInnerHTML={{ __html: desc }} />
          }


          <Button white className='shadow' to={`/${platform}/get-started`}>Get Started</Button>
          <Button white className='shadow' to={this.getBackLink(platform)}>Samples</Button>
          <Button white className='shadow' to={`/${platform}/download`}>Download</Button>

          <p>To run this sample, get started with a <Link to="https://dev.apryse.com/">free trial</Link> of Apryse SDK.</p>

          {
            files.length > 1 &&
            <Tabs
              selected={files[this.state.selectedTabIndex].language}
              autoWidth
              onClick={(key) => {
                this.setState({
                  selectedTabIndex: files.findIndex(p => p.language === key)
                })
              }}
              data={files.map(file => {
                return {
                  key: file.language,
                  label: getUserFacingLanguage(file.language)
                }
              })}
            />
          }
          
          <HTMLToReact
            html={html}
            language={files[this.state.selectedTabIndex].language}
            pathname={location.pathname}
            noTabs
          />
        </Container>  
      </div>
    );
  }
}